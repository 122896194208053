<!-- 转移历史 -->
<template>
  <div class="CapitalTurnRecord">
    <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;">
      <!--   转出人   -->
      <el-table-column :label="$t('Transferor')" prop="outUserName" align="center" />
      <!--   转出编号   -->
      <el-table-column :label="$t('transfer.out.no')" prop="outUserCode" align="center" />
      <!--   转入编号   -->
      <el-table-column :label="$t('toChangeInto.number')" prop="inUserName" align="center" />
      <!--   转入人   -->
      <el-table-column :label="$t('toChangeInto.people')" prop="inUserCode" align="center" />
      <!--   兑换类型   -->
      <el-table-column :label="$t('exchange.type')" prop="acType" align="center">
<!--        <template slot-scope="{row}">-->
<!--          <span v-if="row.acType === 'F$'">{{ 'W$' }}</span>-->
<!--          <span v-else>{{ row.acType }}</span>-->
<!--        </template>-->
      </el-table-column>
      <!--   金额   -->
      <el-table-column :label="$t('Account.money')" prop="inMoney" align="center" />
      <!--   汇率   -->
      <el-table-column :label="$t('bdNetWorkCostReport.rateCH')" prop="inRate" align="center" />
      <!--   状态   -->
      <el-table-column :label="$t('fiBankbookTemp.status')" prop="status" align="center">
       <template slot-scope="{row}">
         <span v-if="row.status === '1'"> {{ $t('app.carry.out')}}</span>
       </template>
      </el-table-column>
      <!--   创建时间   -->
      <el-table-column :label="$t('Common.createTime')" prop="createTime" align="center" />
    </el-table>
    <!--    分页-->
    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-button style="float: right; margin-bottom: 10px" type="warning" @click="continueBtn" plain>{{$t('btn.continue')}}</el-button>
  </div>
</template>
<script>
  import { getFiAcTrans } from "@/api/rus/api"
  import Pagination from '@/components/Pagination'
  export default {
    name: 'CapitalTurnRecord',
    components: { Pagination },
    data () {
      return {
        listLoading: false,
        list: [],
        listQuery: {
          page: 1,
          limit: 10
        },
        total: 0
      }
    },
    created () {
      this.getList()
    },
    methods: {
      getList () {
        this.listLoading = true
        getFiAcTrans({ ...this.listQuery }).then((res) => {
          this.list = res.data.records
          this.list.forEach(v=>{
            if(v.acType === 'B$'){
              v.acType = 'MXN'
            }
          })
          this.total = +res.data.total
          this.listLoading = false
        }).catch(() => {
          this.listLoading = false
        })
      },
      continueBtn () {
        this.$router.push({ path: 'capital-turn' })
      }
    }
  }
</script>

 <style style lang = "scss" scoped >
</style>
